import React from 'react';
import { useTranslation } from "react-i18next";
import logo from '../../../assets/images/logo.svg';
import logoText from '../../../assets/images/logo-text.svg';
import styles from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  
  return (
    <footer className={styles.footer}>
      <div className='container'>
        <div className={styles.footerContent}>
          <div className={styles.leftSide}>
            <div className={styles.logoWrap}>
              <img src={logo} alt="Logo" className={styles.logo} />
              <img src={logoText} alt="Logo" className={`${styles.logoText} logoText`} />
            </div>
            <div className={styles.footerNav}>
              <div className={styles.itemBlock} >
                <div className={`${styles.itemBlock} ${styles.navTitle}`}>
                  Route de la Corniche 4, 1066 Epalinges, Switzerland
                </div>
                <div className={styles.itemBlock}>
                  <a href={`mailto:support@${hostname}`} className={styles.navTitle}>
                    support@{hostname}
                  </a>
                </div>
              </div>
              <div className={styles.itemBlock}>
                <a  
                  href="/docs/Terms.pdf"
                  target="_blank"
                  className={styles.navTitle}
                >
                  {t('footer.docs.terms')}
                </a>
              </div>
            </div>
          </div>
          <div className={styles.rightSide}>
            {/* <div className={styles.navTitle}>{t('footer.disclaimer.title')}</div>
            <div className={styles.text}>
              <p>{t('footer.disclaimer.text_01')}</p>
              <p>{t('footer.disclaimer.text_02')}</p>
            </div> */}
            <div className={styles.copyright}>{t('footer.rights')}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;