import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from './Help.module.scss';
import { Fade } from "react-reveal";

import icon1 from '../../../assets/images/partners/image.png';
import icon2 from '../../../assets/images/partners/image1.png';
import icon3 from '../../../assets/images/partners/image2.png';
import icon4 from '../../../assets/images/partners/stars.png';

const Help = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <Fade bottom>
          <div className={`${styles.title} font-32-24`}>{t('contacts.title')}</div>
          <div className={`${styles.text} ${styles.centered} font-20-16`}>{t('contacts.text')}</div>
          <div className={styles.list}>
            <div className={styles.listItem}>
              <div className={styles.flex}>
                <div className={styles.listItemIcon}><img src={icon1} alt='' /></div>
                <div className={`${styles.text} font-20-16`}>{t('contacts.country1')}</div>
              </div>
              <div>
                <a href="tel:+420533440834" className={`${styles.listItemTitle} font-32-24`}>+420533440834</a>
              </div>
            </div>
            <div className={styles.listItem}>
              <div className={styles.flex}>
                <div className={styles.listItemIcon}><img src={icon2} alt='' /></div>
                <div className={`${styles.text} font-20-16`}>{t('contacts.country2')}</div>
              </div>
              <div>
                <a href="tel:+48669767044" className={`${styles.listItemTitle} font-32-24`}>+48669767044</a>
              </div>
            </div>
            <div className={styles.listItem}>
              <div className={styles.flex}>
                <div className={styles.listItemIcon}><img src={icon3} alt='' /></div>
                <div className={`${styles.text} font-20-16`}>{t('contacts.country3')}</div>
              </div>
              <div>
                <a href="tel:+441970450393" className={`${styles.listItemTitle} font-32-24`}>+441970450393</a>
              </div>
            </div>
            <div className={`${styles.listItem} ${styles.row}`}>
              <div className={`${styles.title} font-32-24`}>{t('contacts.country.title')}</div>
              <div className={styles.flex}>
                <div className={styles.listItemIcon}><img src={icon4} alt='' /></div>
                <div className={`${styles.text} font-20-16`}>{t('contacts.rating')}</div>
              </div>
              <div className={styles.flex}>
                <div className={`${styles.text} font-20-16`}>{t('contacts.chat')}</div>
                <div className={`${styles.text} font-16-14`}>{t('contacts.since')}</div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Help;