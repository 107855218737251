import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Partners from '../components/GlodalComponents/Partners/Partners';
import FormWrap from '../components/GlodalComponents/FormWrap/FormWrap';

function PartnersPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Alliance Consulting · Fees</title>
        <meta name="title" content="Explore Transparent Fees and Commissions on Alliance Consulting" />
        <meta property="og:title" content="Explore Transparent Fees and Commissions on Alliance Consulting" />
        <meta property="twitter:title" content="Explore Transparent Fees and Commissions on Alliance Consulting" />

        <meta name="description" content="Learn about the transparent and low fees and commission details at Alliance Consulting, empowering you with confidence for your trading journey." />
        <meta property="og:description" content="Learn about the transparent and low fees and commission details at Alliance Consulting, empowering you with confidence for your trading journey." />
        <meta property="twitter:description" content="Learn about the transparent and low fees and commission details at Alliance Consulting, empowering you with confidence for your trading journey." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='partners-page ovf-hidden'>
        <Header />
        <Partners />
        <FormWrap />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default PartnersPage;
