import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/HomePage/Banner/Banner';
import Partners from '../components/GlodalComponents/Partners/Partners';
import Discover from '../components/HomePage/Discover/Discover';
import Differ from '../components/HomePage/Differ/Differ';
import Counts from '../components/HomePage/Counts/Counts';
import Value from '../components/HomePage/Value/Value';
import Slider from '../components/HomePage/Slider/Slider';

function HomePage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Alliance Consulting</title>
        <meta name="title" content="Alliance Consulting: Your Gateway to the Crypto Universe" />
        <meta property="og:title" content="Alliance Consulting: Your Gateway to the Crypto Universe" />
        <meta property="twitter:title" content="Alliance Consulting: Your Gateway to the Crypto Universe" />

        <meta name="description" content="Explore Alliance Consulting, the state-of-the-art cryptocurrency trading platform, where innovation and opportunity meet. Dive into a realm of digital assets, advanced tools, and a thriving community of traders." />
        <meta property="og:description" content="Explore Alliance Consulting, the state-of-the-art cryptocurrency trading platform, where innovation and opportunity meet. Dive into a realm of digital assets, advanced tools, and a thriving community of traders." />
        <meta property="twitter:description" content="Explore Alliance Consulting, the state-of-the-art cryptocurrency trading platform, where innovation and opportunity meet. Dive into a realm of digital assets, advanced tools, and a thriving community of traders." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='ovf-hidden main-page'>
        <Header />
        <Banner />
        <Partners />
        <Discover />
        <Differ />
        <Counts />
        <Value />
        <Slider />
        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default HomePage;
