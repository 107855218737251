import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import styles from './Counts.module.scss';
import { Fade } from "react-reveal";

const Counts = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.text} font-16-14`}>{t('main.five.text0')}</div>
        <div className={styles.list}>
          <Fade left>
            <div className={styles.listItem}>
              <div className={`${styles.listItemTitle} font-24-16`}>{t('main.five.list1.title')}</div>
              <div className={`${styles.listItemText} font-40-24`}>{t('main.five.list1.text')}</div>
            </div>
          </Fade>
          <Fade right>
            <div className={styles.listItem}>
              <div className={`${styles.listItemTitle} font-24-16`}><Trans>{t('main.five.list2.title')}</Trans></div>
              <div className={`${styles.listItemText} font-40-24`}>{t('main.five.list2.text')}</div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Counts;
