import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Zoom, Fade, Reveal } from "react-reveal";

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.left}>
          <Fade right><h1 className={`${styles.title} font-40-24`}>{t('about.first.title')}</h1></Fade>
          <Fade left><div className={`${styles.text} font-16-14`}><Trans>{t('about.first.text')}</Trans></div></Fade>
        </div>
      </div>
    </section>
  );
};

export default Banner;