import React from "react";
import styles from './Establishment.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import image1 from '../../../assets/images/about/image1.webp';
import image2 from '../../../assets/images/about/image2.webp';

const Establishment = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.row}>
          <Fade left>
            <div className={styles.item}>
              <img src={image1} alt="" />
            </div>
          </Fade>
         <Fade right>
           <div className={`${styles.item} ${styles.paddL}`}>
            <h1 className={`${styles.title} font-40-24`}>{t('about.sec.title')}</h1>
            <div className={`${styles.text} font-16-14`}><Trans>{t('about.sec.text1')}</Trans></div>
          </div>
        </Fade>
        </div>
        <div className={styles.row}>
         <Fade left>
          <div className={`${styles.item} ${styles.paddR}`}>
            <div className={`${styles.text} font-16-14`}><Trans>{t('about.sec.text2')}</Trans></div>
            <div className={`${styles.text} font-16-14`}><Trans>{t('about.sec.text3')}</Trans></div>
          </div>
        </Fade>
        <Fade right>
          <div className={styles.item}>
            <img src={image2} alt="" />
          </div>
        </Fade>
        </div>
      </div>
    </section>
  );
};

export default Establishment;