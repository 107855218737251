import React from 'react';
import { useTranslation } from "react-i18next";
import styles from './Value.module.scss';
import { Fade } from "react-reveal";

import icon1 from '../../../assets/images/homepage/values1.svg';
import icon2 from '../../../assets/images/homepage/values2.svg';
import icon3 from '../../../assets/images/homepage/values3.svg';
import image from '../../../assets/images/homepage/image1.webp';

const Value = () => {
  const { t } = useTranslation();
  const list = t('main.six.list', { returnObjects: true });
  const icons = [icon1, icon2, icon3];

  return (
    <div className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <Fade left>
          <div className={styles.left}>
            <div className={`${styles.title} font-40-32`}>{t('main.six.title')}</div>
            <img src={image} alt='' />
          </div>
        </Fade>
        <div className={styles.list}>
          {list.map((item, index) => (
            <Fade right delay={index * 100 + 1}>
              <div className={styles.listItem} key={index}>
                <div className={styles.icon}><img src={icons[index]} alt='' /></div>
                <div>
                  <div className={`${styles.listItemTitle} font-24-20`}>{item.title}</div>
                  <div className={`${styles.text} font-16-14`}>{item.text}</div>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Value;
