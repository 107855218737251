import React from 'react';
import { useTranslation } from "react-i18next";
import styles from './Discover.module.scss';
import { Fade } from "react-reveal";

import image from '../../../assets/images/homepage/image.webp';

const Discover = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <Fade left>
          <div className={styles.item}>
            <div className={`${styles.title} font-40-32`}>{t('main.third.title')}</div>
            <div className={`${styles.subtitle} font-24-16`}>{t('main.third.text0')}</div>
            <div className={`${styles.text} font-16-14`}>{t('main.third.text1')}</div>
          </div>
        </Fade>
        <Fade right >
          <div className={styles.image}>
            <img src={image} alt='' />
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Discover;
