import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade, Reveal } from "react-reveal";
import Form from '../../GlodalComponents/Form/Form';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.left}>
          <Fade left><h1 className={`${styles.title} font-64-36`}>{t('main.first.title0')}</h1></Fade>
          <Fade right><div className={`${styles.subtitle} font-40-24`}><Trans>{t('main.first.title1')}</Trans></div></Fade>
          <Fade left><div className={`${styles.subtext} font-20-16`}><Trans>{t('main.first.text0')}</Trans></div></Fade>
          <Fade right><div className={`${styles.text} font160-14`}><Trans>{t('main.first.text1')}</Trans></div></Fade>
        </div>
        <Reveal effect="zoomIn">
          <div className={styles.right}>
            <Form isMain  />  
          </div>
        </Reveal>
      </div>
    </section>
  );
};

export default Banner;