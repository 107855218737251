import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/AboutPage/Banner/Banner';
import Establishment from '../components/AboutPage/Establishment/Establishment';

function AboutPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Alliance Consulting · About us</title>
        <meta name="title" content="About Alliance Consulting: Shaping the Future of Cryptocurrency Trading" />
        <meta property="og:title" content="About Alliance Consulting: Shaping the Future of Cryptocurrency Trading" />
        <meta property="twitter:title" content="About Alliance Consulting: Shaping the Future of Cryptocurrency Trading" />

        <meta name="description" content="Explore Alliance Consulting's mission, vision, and dedication to empowering traders while driving the evolution of blockchain technology. Come along on our quest for a decentralized financial future." />
        <meta property="og:description" content="Explore Alliance Consulting's mission, vision, and dedication to empowering traders while driving the evolution of blockchain technology. Come along on our quest for a decentralized financial future." />
        <meta property="twitter:description" content="Explore Alliance Consulting's mission, vision, and dedication to empowering traders while driving the evolution of blockchain technology. Come along on our quest for a decentralized financial future." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='about-page ovf-hidden'>
        <Header />
        <Banner />
        <Establishment />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AboutPage;
