import HomePage from '../pages/HomePage';
import ServicesPage from '../pages/ServicesPage';
import AboutPage from '../pages/AboutPage';
import PartnersPage from '../pages/PartnersPage';
import ContactsPage from '../pages/ContactstPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/services', element: ServicesPage },
    { path: '/:lang/about', element: AboutPage },
    { path: '/:lang/partners', element: PartnersPage},
    { path: '/:lang/contacts', element: ContactsPage },
];


export const headerRoutes = [
    { path: 'services', text: 'header.services' },
    { path: 'about', text: 'header.about' },
    { path: 'partners', text: 'header.partners' },
    { path: 'contacts', text: 'header.contacts' },
];