import React from 'react';
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import styles from './Partners.module.scss';
import { Fade } from "react-reveal";

import image1 from '../../../assets/images/homepage/merg/image1.webp';
import image2 from '../../../assets/images/homepage/merg/image2.webp';
import image3 from '../../../assets/images/homepage/merg/image3.webp';
import image4 from '../../../assets/images/homepage/merg/image4.webp';
import image5 from '../../../assets/images/homepage/merg/image5.webp';
import image6 from '../../../assets/images/homepage/merg/image6.webp';
import image7 from '../../../assets/images/homepage/merg/image7.webp';
import image8 from '../../../assets/images/homepage/merg/image8.webp';
import image9 from '../../../assets/images/homepage/merg/image9.webp';
import image10 from '../../../assets/images/homepage/merg/image10.webp';

const Partners = () => {
  const { t } = useTranslation();
  const marquee1 = [image1, image2, image3, image4, image5];
  const marquee2 = [image6, image7, image8, image9, image10];

  return (
    <div className={styles.wrap}>
      <Fade bottom><div className={`${styles.title} font-40-32`}>{t('main.sec.title')}</div></Fade>
      <div className={styles.marqueeContainer}>
        <Marquee autoFill>
          {marquee1.map((item, index) => (
            <div key={index} className={styles.merqueeItem}>
                <img src={item} alt='' />
            </div>
          ))}
        </Marquee>
      </div>
      <Marquee autoFill direction='right'>
        {marquee2.map((item, index) => (
          <div key={index} className={styles.merqueeItem}>
              <img src={item} alt='' />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default Partners;
