import React from "react";
import styles from './FormWrap.module.scss';
import Form from '../Form/Form';

const FormWrap = ({ isMain }) => {
    
    return (
        <div className={styles.wrap}>
            <div className={`${styles.container} container`}>
                <div className={styles.content}>
                    <Form />
                </div>
            </div>

        </div>
    );
};

export default FormWrap;