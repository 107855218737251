import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from './Services.module.scss';
import Button from '../../GlodalComponents/Button/Button';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Fade } from "react-reveal";

import icon from '../../../assets/images/homepage/arr-right.svg';
import icon1 from '../../../assets/images/services/icon1.svg';
import icon2 from '../../../assets/images/services/icon2.svg';
import icon3 from '../../../assets/images/services/icon3.svg';
import icon4 from '../../../assets/images/services/icon4.svg';
import icon5 from '../../../assets/images/services/icon5.svg';
import icon6 from '../../../assets/images/services/icon6.svg';

const Services = () => {
  const { t } = useTranslation();
  const list = t('service.first.list', { returnObjects: true });
  const icons = [icon1, icon2, icon3, icon4, icon5, icon6];
  const [swiper, setSwiper] = useState({});
  const [active, setActive] = useState(0);
  const isMobile = window.innerWidth <= 768;

  return (
    <div className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.title} font-32-24`}>{t('service.first.title')}</div>
        {isMobile ? 
        (
          <div className={styles.mart}>
            <Swiper
            modules={[Navigation]}
            spaceBetween={20}
            slidesPerGroup={1}
            loop={false}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              769: {
                slidesPerView: 1.2,
              },
              1024: {
                slidesPerView: 2.2,
              }
            }}
            onInit={(e) => { setSwiper(e) }}
            onSlideChange={(e) => setActive(e.activeIndex)}
          >
            {list.map((item, index) => (
              <SwiperSlide key={index} className={styles.listItem}>
                <div className={styles.flex}>
                  <div className={styles.listItemIcon}><img src={icons[index]} alt='' /></div>
                  <div className={`${styles.listItemTitle} font-24-16`}>{item.title}</div>
                </div>
                <div className={`${styles.text} font-16-14`}>{item.text}</div>
                <div className={`${styles.centered} font-16-14`}><Trans>{item.text0}</Trans></div>
                <div className={`${styles.center} mt-btn`}><Button>{t('btn.return')}</Button></div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={styles.sliderBtns}>
            <button 
              className={`${styles.sliderBtn} ${styles.sliderBtnPrev} ${active === 0 ? styles.inactive : ''}`}
              onClick={() => swiper.slidePrev()}
            >
              <img src={icon} alt="Prev" />
            </button>
            <button 
              className={`${styles.sliderBtn} ${styles.sliderBtnNext} ${active === swiper.snapGrid?.length - 1 ? styles.inactive : ''}`}
              onClick={() => swiper.slideNext()}
            >
              <img src={icon} alt="Next" />
            </button>
          </div>
          </div>
              
        ) : (
          <div className={styles.list}>
            {list.map((item, index) => (
              <Fade bottom delay={index * 100 + 1}>
                <div className={styles.listItem} key={index}>
                  <div className={styles.flex}>
                    <div className={styles.listItemIcon}><img src={icons[index]} alt='' /></div>
                    <div className={`${styles.listItemTitle} font-24-16`}>{item.title}</div>
                  </div>
                  <div className={`${styles.text} font-16-14`}>{item.text}</div>
                  <div className={`${styles.centered} font-16-14`}><Trans>{item.text0}</Trans></div>
                  <div className={`${styles.center} mt-btn`}><Button>{t('btn.return')}</Button></div>
                </div>
              </Fade>
            ))}
          </div>
          ) 
        }
      </div>
    </div>
  );
};

export default Services;
