import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Services from '../components/ServicesPage/Services/Services';
import FormWrap from '../components/GlodalComponents/FormWrap/FormWrap';

function AccountPage() {
  return (
    <HelmetProvider>
      <Helmet>
      <title>Alliance Consulting · Account types</title>
        <meta name="title" content="Alliance Consulting Account Options: Customized for Your Trading Adventure" />
        <meta property="og:title" content="Alliance Consulting Account Options: Customized for Your Trading Adventure" />
        <meta property="twitter:title" content="Alliance Consulting Account Options: Customized for Your Trading Adventure" />

        <meta name="description" content="Alliance Consulting provides a variety of account options designed to match your skill level and preferences. Whether you're a beginner or an experienced trader, discover the ideal account type for your crypto journey." />
        <meta property="og:description" content="Alliance Consulting provides a variety of account options designed to match your skill level and preferences. Whether you're a beginner or an experienced trader, discover the ideal account type for your crypto journey." />
        <meta property="twitter:description" content="Alliance Consulting provides a variety of account options designed to match your skill level and preferences. Whether you're a beginner or an experienced trader, discover the ideal account type for your crypto journey." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='services-page ovf-hidden'>
        <Header />
        <Services />
        <FormWrap />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AccountPage;
